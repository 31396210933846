// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  baseUrl:"https://developer.sangli.io/backend/",
    dynamicActionBaseUrl:"https://developer-action.sangli.io/",
    podLoggerBaseUrl:'ws://169.38.98.196:30973/',
    monitoringStatusRefreshInSeconds: 1000 * 15,
    monitoringNodeExportBinaryURL:
        "sudo curl https://developer.sangli.io/infra-monitor/monitor | bash -"
};
export const RazorpayId = {
  razorpay_id: 'rzp_test_dn7jVSKeY9fyPM'
}
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
